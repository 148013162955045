.education-title{
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    text-align: center;
    font-weight: 600;
}

.education-pic {
    padding-bottom: 10px;
    width: 45px;
    filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.305));
}

.titulo {
    font-size: 16px;
}

.texto {
    font-size: 14px;
    font-weight: 500;
}


@media screen and (min-width: 769px) {
    .education-title{
        width: 200px;
    }
}

@media screen and (min-width: 1025px) {
    .education-pic {
        width: 45px;
    }
    .titulo {
        font-size: 20px;
        width: 300px;
    }
    .texto {
        font-size: 16px;
    }
}