.reason-container{
    margin-bottom: 40px;
    padding: 15px 25px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease 0s;
    cursor: pointer;    
    height: 200px;
}

.reason-container:hover{
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
    transform: translateY(-5px);
    border-bottom: 5px solid #30B2DC;
}

.logo{
    width: 45px;
    height: 45px;
    margin: 10px;
}

.title{
    font-weight: 600;
    color: #282828;
}

.descrip{
    max-width: 230px;
    font-size: 16px;
    color: #747474;
}


@media screen and (max-width: 500px) {
    .logo{
        width: 45px;
        height: 45px;
        margin: 5px;
    }
}


@media screen and (min-width: 1025px) {
    .reason-container{
        margin: 20px;
    }
    .logo{
        width: 45px;
        height: 45px;
        margin: 15px;
    }
}