.home-background{
    background-repeat: no-repeat;
    background-size: contain;
}

.home{
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.home-title{
    color: #30B2DC;
    font-size: 25px;
    font-weight: 600;
    line-height: 80px;
}

/* BOTON */

.home-button {
    margin: 0;
    width: 150px;
    height: 45px;
    display: inline-block;
    position: relative;
    color: white;
    background-color: #30B2DC;
    border: none;
    cursor: pointer;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    font-weight: 300;
    font-size: 10px;
    letter-spacing: 1.5px;
}

.home-button::before, .home-button::after{
    content:"";
    width: 0;
    height: 2px;
    position: absolute;
    transition: all 0.2s linear;
    background: #fff;
}

.home-button:hover::before, .home-button:hover::after{
    width: 100%;
}

.home-button:hover span::before, .home-button:hover span::after{
    height: 100%;
}

.home-button::before, .home-button::after{
    transition-delay: 0.2s;
}

.home-button span::before, .home-button span::after{
    transition-delay: 0s;
}

.home-button::before{
    right: 0;
    top: 0;
}

.home-button::after{
    left: 0;
    bottom: 0;
}

.home-button span::before{
    left: 0;
    top: 0;
}

.home-button span::after{
    right: 0;
    bottom: 0;
}

.home-button:hover::before, .home-button:hover::after{
    transition-delay: 0s;
}

.home-button:hover span::before, .home-button:hover span::after{
    transition-delay: 0.2s;
}

.home-button:hover{
    background-color: white;
    color: #da2ca5;
    border: 1px solid #da2ca5;
    transition: 1s;
}


@media screen and (max-width: 420px) {
    .home-background{
        height: 700px;
        background-image: url(../../assets/fondo300.jpg);
    }
    .home{
        height: 300px;
        padding: 20px 0px 0px 20px;
    }
    .home-title{
        line-height: 70px;
        letter-spacing: 1px;
    }
}


@media screen and (min-width: 421px) and (max-width: 530px) {
    .home-background{
        height: 700px;
        background-image: url(../../assets/fondo400.jpg);
    }
    .home{
        height: 300px;
        padding: 20px 0px 0px 20px;
    }
    .home-title{
        line-height: 70px;
        letter-spacing: 1px;
    }
}

@media screen and (min-width: 531px) and (max-width: 600px) {
    .home-background{
        height: 700px;
        background-image: url(../../assets/fondo420.jpg);
    }
    .home{
        height: 250px;
        padding: 20px 0px 0px 20px;
    }
    .home-title{
        line-height: 70px;
        letter-spacing: 1px;
        white-space: pre-line;
    }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
    .home-background{
        height: 600px;
        background-image: url(../../assets/fondo768.jpg);
    }
    .home{
        height: 300px;
        padding: 20px 0px 0px 20px;
    }
    .home-title{
        letter-spacing: 1.5px;
        white-space: pre-line;
    }
}


@media screen and (min-width: 769px) and (max-width: 1024px) {
    .home-background{
        height: 550px;
        background-image: url(../../assets/fondo1024.jpg);
    }
    .home{
        height: 300px;
        padding: 20px 0px 0px 20px;
    }
    .home-title{
        letter-spacing: 2px;
        white-space: pre-line;
    }
}

@media screen and (min-width: 1025px) {
    .home-background{
        height: 800px;
        background-image: url(../../assets/fondo.jpg);
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    .home{
        width: 100%;
        height: 325px;
        padding-top: 20px;
        max-width: 1024px;
    }
    .home-title{
        font-size: 35px;
        letter-spacing: 2.5px;
        line-height: 80px;
        white-space: pre-line;
    }
    .home-button {
        width: 200px;
        height: 60px;
        font-size: 14px;
        letter-spacing: 2px;
    }
}

@media screen and (min-width: 1400px) {
    .home-background{
        background-image: url(../../assets/fondo1400.jpg);
    }
    .home{
        height: 350px;
    }
}

@media screen and (min-width: 1850px) {
    .home-background{
        background-image: url(../../assets/fondo1800.jpg);
    }
    .home{
        height: 350px;
    }
}