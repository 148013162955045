.Courses-responsive {
    color: #30B2DC;
}

.flex-courses {
    height: 425px;
    display: flex;
    justify-content: space-between;
}

.courses-text-container{
    height: 100%;
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: justify;
}

.h2-courses{
    color: #30B2DC;
    padding-bottom: 50px;
    font-size: 35px;
    font-weight: 600;
    letter-spacing: 1px;
}

.courses-img-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}


@media screen and (max-width: 500px) {
    .Courses {
        display: none;
    }
    .Courses-responsive {
        height: 1100px;
        padding-top: 20px;
        text-align: center;
    }
    .h2-courses{
        padding-bottom: 15px;
    }
    .flex-courses {
        height: 600px;
        align-items: center;
        flex-direction: column;
    }
    .courses-text-container{
        width: 350px;
    }
    .img-courses{
        width: 350px;
        padding: 60px 0px;
    }
}

@media screen and (min-width: 501px) and (max-width: 812px) {
    .Courses {
        display: none;
    }
    .Courses-responsive {
        height: 1050px;
        padding-top: 70px;
        text-align: center;
    }
    .h2-courses{
        padding-bottom: 20px;
    }
    .flex-courses {
        height: 725px;
        align-items: center;
        flex-direction: column;
    }
    .img-courses{
        width: 375px;
        padding: 60px 0px;
    }
}

@media screen and (min-width: 813px) and (max-width: 1024px) {
    .Courses-responsive {
        display: none;
    }
    .Courses {
        padding: 40px 30px 0px;
        height: 700px;
    }
    .courses-text-container{
        padding-left: 50px;
    }
    .img-courses{
        width: 375px;
    }
}

@media screen and (min-width: 1025px) {
    .Courses-responsive {
        display: none;
    }
    .Courses {
        margin: 0 auto;
        padding-top: 60px;
        height: 700px;
        max-width: 1024px;
    }
    .img-courses{
        width: 425px;
    }
}