.certification-container{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 15px 25px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease 0s;  
    width: 35%;
    height: 450px;
    border-bottom: 5px solid #30B2DC;
    background-color: #ffff;
    color: #747474;
}


.certification-icon{
    width: 40px;
    height: 40px;
}




@media screen and (max-width: 768px) {
    .logo{
        width: 45px;
        height: 45px;
        margin: 5px;
    }

    .certification-container{
        width: 80%;
    }
}


@media screen and (min-width: 1025px) {
    .reason-container{
        margin: 20px;
    }
    .logo{
        width: 45px;
        height: 45px;
        margin: 15px;
    }
}