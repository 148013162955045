.WhyMe{
    color: #30B2DC;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
}

.whyme-title{
    font-size: 35px;
    font-weight: 600;
    letter-spacing: 1px;
}

.whyme-title:hover {
    color: #da2ca5;
    transition: .5s;
}

.whyme-container{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}


@media screen and (max-width: 574.9px) {
    .WhyMe{
        height: 1950px;
        gap: 60px;
    }
    .whyme-container{
        justify-content: space-around;
    }
}

@media screen and (min-width: 575px) and (max-width: 869.9px) {
    .WhyMe{
        height: 1150px;
        gap: 70px;
    }
    .whyme-container{
        justify-content: space-around;
    }
}

@media screen and (min-width: 870px) and (max-width: 1024px) {
    .WhyMe{
        height: 825px;
        gap: 60px;
    }
    .whyme-container{
        justify-content: space-around;
    }
}

@media screen and (min-width: 1025px) {
    .WhyMe{
        height: 850px;
        gap: 50px;
    }
    .whyme-container{
        justify-content: space-between;
        max-width: 1024px;
    }
}