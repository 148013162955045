.AboutMe{
    margin: 0 auto;
    display: flex;
}

.about-me-title{
    color: #30B2DC;
    display: flex;
    flex-flow: column;
    gap: 25px;
}

.about-me-title h2{
    font-size: 50px;
    font-weight: 900;
    margin: 0;
}

.about-me-title h3{
    font-size: 35px;
    font-weight: 600;
    margin: 0;
}

.about-me-title p{
    text-align: justify;
    color: #848484;
}

.rosa:hover {
    color: #da2ca5;
    transition: .5s;
}

.about-me-pic{
    border: 3px solid white;
    box-shadow: 5px 5px 10px 5px rgba(194, 194, 194, 0.4);
}


@media screen and (max-width: 500px) {
    .AboutMe{
        height: 950px;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-bottom: 50px;
    }
    .about-me-pic{
        margin: 0px 0px 40px;
        height: 375px;
    }
    .about-me-title p{
        width: 350px;
    }
}

@media screen and (min-width: 501px) and (max-width: 768px) {
    .AboutMe{
        height: 950px;
        padding-bottom: 40px;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .about-me-pic{
        margin: 0px 0px 40px;
        height: 375px;
    }
    .about-me-title h2{
        text-align: center;
    }
    .about-me-title p{
        width: 400px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .AboutMe{
        height: 575px;
        padding-left: 30px;
        justify-content: space-between;
    }
    .about-me-pic{
        margin: 50px 50px 0px 0px;
        height: 375px;
    }
    .about-me-title p{
        width: 345px;
    }
}

@media screen and (min-width: 1025px) {
    .AboutMe{
        height: 600px;
        max-width: 1024px;
        justify-content: space-between;
    }
    .about-me-pic{
        margin: 30px 50px 0px 0px;
        height: 425px;
    }
    .about-me-title h2{
        font-size: 60px;
    }
    .about-me-title h3{
        font-size: 40px;
    }
    .about-me-title p{
        width: 392px;
    }
}