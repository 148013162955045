#footerBackground{
    height: 1100px;
    background-color: #092a37;
}

.footer {
    margin: 0 auto;
    padding: 50px 30px 0px;
    color: rgb(228, 226, 226);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer ul {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
}

.footer li {
    margin: 15px 0px;
}

.footerGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.footer h2 {
    margin-bottom: 30px;
    font-size: 20px;
}

.footer p {
    font-size: 16px;
    line-height: 25px;
}


#textoFooter {
    line-height: 45px;
    padding: 0px 50px;
}

#footerTechbay {
    width: 100%;
}

#footerLogos {
    margin-top: 55px;
    font-weight: 600;
    letter-spacing: 1px;
}

#footerLogos a {
    text-decoration: none;
    color: white;
}


@media screen and (max-width: 596.9px) {
    #footerBackground{
        height: 1250px;
    }
}

@media screen and (min-width: 1025px) {
    #footerBackground{
        height: 502px;
    }
    .footer {
        padding: 50px 0px 0px;
        max-width: 1024px;
    }
    .footer ul {
        flex-direction: row;
        text-align: start;
    }
    .footer li {
        margin: 0px;
    }
    .footerGrid {
        display: inline;
        grid-template-columns: 1fr 1fr;
    }
    .footer p {
        line-height: 45px;
    }
    #textoFooter {
        line-height: 58px;
        padding: 0;
    }
    #footerTechbay {
        width: 300px;
    }
    #footerLogos {
        margin-top: 40px;
    }
}






/* MATRICULAS */

.footer-top-container {
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1023.9px) {
    .footer-top {
        flex-direction: column;
    }
}

@media screen and (min-width: 1024px) {
    .main-footer {
        padding: 10px 0px;
    }
}

.footer-top {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    width: 100%;
    align-items: center;
    padding: 50px 20px;
    max-width: 1024px;
}

.img-footer {
    width: 100%;
    max-width: 300px;

}