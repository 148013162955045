.checkbox {
	opacity: 0;
	position: absolute;
    top: 50%;
}

.label {
	background-color: rgb(245, 245, 245);
	border-radius: 50px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px;
	position: relative;
	height: 16px;
	width: 40px;
	transform: scale(1.5);
	border: 1px solid rgb(169, 169, 169);
}
.ball img{
    height: 22px;
	width: 22px;
}
.label .ball{
	background-color: transparent;
	border-radius: 50%;
	position: absolute;
	top: 2px;
	left: 2px;
	height: 22px;
	width: 22px;
	transform: translateX(0px);
	transition: transform 0.2s linear;
    overflow: hidden;
}

.checkbox:checked + .label .ball {
	transform: translateX(24px);
}

.label_text{
    font-size: 7px;
    font-weight: 1000;
    color: black;
}
.container_toggle{
    position: absolute;
    top: 50;
}