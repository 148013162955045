

@media screen and (min-width: 10px) {
    .message-container{
        width: 70%;
        height: 90%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        border-radius: 30px;
        border: 3px solid #FFF;
        background: #3CA7C5;
        box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
    }
    .comment {
        font-size: 13px;
        font-style: normal;
        line-height: 20px;
        letter-spacing: 0.6px;
        padding: 0px 10px;
    }
    .name {
        font-size: 16px;
        color: white;
        font-weight: 600;
        letter-spacing: .8px;
    }
}

@media screen and (min-width: 501px) {
    .message-container{
        width: 75%;
        justify-content: space-around;
    }
    .comment {
        font-size: 16px;
        line-height: normal;
        padding: 0px 20px;
    }
}

@media screen and (min-width: 1025px) {
    .comment {
        padding: 0px 30px;
        letter-spacing: .8px;
    }
    .name {
        font-size: 18px;
    }
}
