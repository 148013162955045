#nav-background {
    width: 100%;
    top: 0;
    position: sticky;
    background-color: white;
    z-index: 999;
    border-bottom: 1px solid rgb(237, 237, 237);
}

.nav {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    align-items: center;
    z-index: 1000;
    border-bottom: 1px solid rgb(237, 237, 237);
    margin: 0 auto;
}

.nav-logo {
    color: #3CA7C5;
    font-weight: 600;
    letter-spacing: 5px;
    transition: all 0.3s ease 0s;
} 

.nav-logo:hover {
    color: #da2ca5;
    transition: .5s;
}

.nav-info {
    display: flex;
    justify-content: flex-end;
    gap: 3%;
} 

.nav-links {
    width: 100px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
} 

.button-nav {
    font-size: 14px;
    height: 25px;
    width: 120px;
    letter-spacing: 1px;
    text-decoration: none;
    background-color: transparent;
    position: relative;
    border: none;
    color: #3CA7C5;
    cursor: pointer;
    transition: all 0.3s;
}

.button-nav:hover {
    color: #da2ca5;
}

.button-nav:after {
    content: "";
    position: absolute;
    background-color: #da2ca5;
    width: 0;
    height: 1px;
    left: 20%;
    bottom: -5px;
    transition: all 0.3s;
}

.button-nav:hover:after {
    width: 60%;
}

.nav-bar-responsive {
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    flex-flow: column wrap;
    z-index: 10001;
}

.nav-responsive {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.nav-info-responsive {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
} 

.toggle-responsive {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
}

.nav-responsive-line {
display: none;
}

@media screen and (max-width: 768px) {
    .nav {
        display: none;
    }
    .nav-responsive-line {
        padding: 20px 0px 20px 20px;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .nav-responsive-links {
        position: relative;
    }
    .toggle {
        position: absolute;
        top: -10px;
        right: 125px;
    }
    .burger-responsive {
        margin-bottom: 60px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .nav {
        padding: 30px;
        height: 40px;
    }
    .button-nav:hover {
        font-size: 15.5px;
    }
}

@media screen and (min-width: 1025px) {
    #nav-background {
        height: 100px;
    }
    .nav {
        height: 100px;
        max-width: 1024px;
    }
    .nav-info {
        width: 60%;
    }
    .button-nav:hover {
        font-size: 16px;
    }
}