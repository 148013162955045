.info-container{
    display: flex;
    justify-content: center;
    padding: 50px 0px;
}

.info{
    display: flex;
    max-width: 1024px;
    width: 100%;
    justify-content: space-around;
}

@media screen and (max-width: 768px) {
    .info{
        height: 100%;
        flex-direction: column;
        gap: 100px;
        align-items: center;
    }
}