.Education{
    display: flex;
    align-items: center;
    background-color: #3CA7C5;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.education-background{
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    white-space: pre-line;
}


@media screen and (max-width: 500px) {
    .Education{
        height: 950px;
    }
    .education-background{
        height: 100%;
        flex-direction: column;
    }
}

@media screen and (min-width: 501px) and (max-width: 768px) {
    .Education{
        height: 950px;
    }
    .education-background{
        height: 100%;
        flex-direction: column;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .education-background{
        height: 350px;
        justify-content: space-around;
    }
}

@media screen and (min-width: 1025px) {
    .education-background{
        height: 400px;
        justify-content: space-between;
        max-width: 1024px;
    }
}